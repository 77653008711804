import "./marketing-popup.css";
import Cookies from "cookies-js";

function marketingPopupInit() {
  let marketingPopEle = document.querySelector("[marketing-popup]");
  let customerLoggedIn = marketingPopEle?.dataset.loggedin;
  let openingPopup = marketingPopEle?.dataset.marketingpopup;
  const POPUP_OPENING_TIME = 10;

  const isFormSubmisstion = isNewsletterSubmitted();

  // Get cookie to track previous user popup close preference
  let isLoginPopupClosed = Cookies.get("is-login-popup-closed");
  let isSubscriptPopupClosed = Cookies.get("is-subscribe-popup-closed");

  if (!customerLoggedIn) {
    // opening tooltip popup
    openMarketingTooltip();
  }

  if (openingPopup == "login" && isLoginPopupClosed !== "true") {
    // Wait 10 sec beforing opening marketing popup
    setTimeout(showMarketingPopups, POPUP_OPENING_TIME * 1000);
  } else if (openingPopup == "subscribe" && isSubscriptPopupClosed !== "true") {
    // opening marketing popup
    isFormSubmisstion
      ? showMarketingPopups()
      : setTimeout(showMarketingPopups, POPUP_OPENING_TIME * 1000);
  }

  popupCloseListener();

  // Show main marketing popup
  function showMarketingPopups() {
    if (!marketingPopEle) return;

    const showPopup = () => {
      // Show marketing popup
      popupVisibility(true);

      // Disable body scroll
      document.body.classList.add("scroll-disable");
    };

    if (
      !marketingPopEle?.classList.contains("subscribed") ||
      isFormSubmisstion
    ) {
      showPopup();
    }
  }

  // newsletter and login popup close trigger and evnts
  function popupCloseListener() {
    const closePopupBtn = document.querySelector(".close-popup");

    // Popup close trigger event
    closePopupBtn.addEventListener("click", (e) => {
      let checkPopup = e.target.dataset.popup;

      // hide popup
      popupVisibility(false);

      // activating scroll
      disableScroll(false);

      // set cookie for restricting popup repoening on other page between expire time.
      restictPopupOpening(checkPopup);
    });
  }

  // Open tooptip
  function openMarketingTooltip() {
    const marketingtooltipEle = document.querySelector(
      "[marketing-tooltip-popup]"
    );

    // open marketing tooltip
    if (marketingtooltipEle.classList.contains("hidden"))
      marketingtooltipEle.classList.remove("hidden");

    marketingtooltipListener();
  }

  // Tooltip listeners and actions
  function marketingtooltipListener() {
    const closeTooltopBtn = document.querySelector(".close-marketing-tooltip");
    const marketingTooktopAction = document.querySelector(
      "[marketing-tooptip-trigger]"
    );

    const marketingTooltipContent = document.querySelector(
      "[marketing-tooptip-content]"
    );

    closeTooltopBtn.addEventListener("click", () =>
      marketingTooltipContent.classList.add("hidden")
    );

    [marketingTooktopAction, marketingTooltipContent].forEach((ele) => {
      ele.addEventListener("click", (e) => {
        if (e.target !== closeTooltopBtn) popupVisibility(true);
      });
    });
  }

  // control page scrolling (enable or disable)
  function disableScroll(status) {
    status
      ? document.body.classList.add("scroll-disable")
      : document.body.classList.remove("scroll-disable");
  }

  // control markting popup visibility
  function popupVisibility(status) {
    status
      ? marketingPopEle.classList.remove("hidden")
      : marketingPopEle.classList.add("hidden");
  }

  // Checking if newsletter form submitted
  function isNewsletterSubmitted() {
    const url = new URL(`${window.location.href}`);
    const urlPerms = new URLSearchParams(url.search);
    const value = urlPerms.get("customer_posted");

    return value;
  }

  // marketing popup close tracking for resticting reopening
  function restictPopupOpening(popup) {
    const EXPIRE_TIME = 60 * 60 * 24 * 1;

    if (popup == "newsletter" && isFormSubmisstion !== "true") {
      Cookies.set("is-subscribe-popup-closed", true, {
        expires: EXPIRE_TIME,
      });
    }

    if (popup == "login") {
      Cookies.set("is-login-popup-closed", true, { expires: EXPIRE_TIME });
    }
  }
}
marketingPopupInit();
